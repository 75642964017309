export const ClientCabinetTypes = Object.freeze({
  CC: "CC",
  C_OWN_PL: "C_OWN_PL",
  C_CC_PL: "C_CC_PL"
});

export const ClientCabinetTypeFormatter = (type) => {
  switch (type) {
    case ClientCabinetTypes.C_OWN_PL:
      return "C";
    case ClientCabinetTypes.C_CC_PL:
      return "C";
    case ClientCabinetTypes.CC:
      return "CC";
    default:
      return ""
  }
}