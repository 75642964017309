import React, {lazy, Suspense, useEffect} from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAuth} from "reduxStore/reducers/auth";
import {getToggleSidebar} from "reduxStore/reducers/toggleSidebar";
import Header from "navigation/Header";
import Sidebar from "navigation/Sidebar";
import Login from "routes/Login";
import {getTableData} from "reduxStore/reducers/tableData";
import {Roles} from "./common/RoleFormatter";
import ProtectedRoutes from "./RouterSettings/ProtectedRoutes";
import PublicRoute from "./RouterSettings/PublicRoutes";
import image from "assets/images/preloader.svg";
import ReloadPageAfterDeployment from "./utils/reloadPageAfterDeployment";
import "utils/Formatters/formatURL";
import {CheckIsAuth} from "./redux/reducers/auth";
import {UseLazyGetMessageApi, websocketMessageTypes} from "./components/websocket/WebsocketApi";
import {UseCloseSocketSession} from "./redux/api/DisconnectSocket";
import {getCookie} from "./utils/api";
import {authConstants} from "./constants/authConstants";
import {showNewFavicon} from "./components/Notifications/Notification";
import {useTranslation} from "react-i18next";
import {appUrls, baseUrl} from "./constants/appUrls";
import 'zone.js';
import "./tracing";

const ROUTES = [
  {
    path: appUrls.users,
    // component: <Users/>,
    component: lazy(() => import('routes/Users')),
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD],
    exact: true,
  },
  {
    path: appUrls.providers,
    // component: <Users/>,
    component: lazy(() => import('routes/Providers/Providers')),
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD],
    exact: true,
  },
  {
    path: appUrls.finApplications,
    // component: <Users/>,
    component: lazy(() => import('routes/FinApplications/FinApplications')),
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD, Roles.ROLE_ACCOUNTING, Roles.ROLE_FINANCIER],
    exact: true,
  },
  {
    path: appUrls.payers,
    // component: <Payers/>, //src/routes/Payers.js
    component: lazy(() => import('routes/Payers')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],
  },
  {
    path: appUrls.payersId,
    // component: <PayersInfo/>, //src/routes/PayersInfo.js
    component: lazy(() => import('routes/PayersInfo')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],
  },
  {
    path: appUrls.paymentInfo,
    // component: <PaymentInfo/>, //src/routes/PaymentInfo.js
    component: lazy(() => import('routes/PaymentInfo')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],
  },
  {
    path: appUrls.home,
    // component: <Clients/>,
    component: lazy(() => import('routes/Clients')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],
  },
  {
    path: appUrls.partnerProgramClients,
    // component: <Clients/>,
    component: lazy(() => import('routes/PartnershipProgram/PPClients')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],
  },
  {
    path: appUrls.partnerProgramRequests,
    // component: <Clients/>,
    component: lazy(() => import('routes/PartnershipProgram/PPRequests')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD],

  },
  {
    path: appUrls.bonusProgramTerms,
    // component: <Clients/>,
    component: lazy(() => import('routes/BonusProgram/BonusProgramPage')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD],

  },
  {
    path: appUrls.bonusPreliminaryReport,
    // component: <Clients/>,
    component: lazy(() => import('routes/BonusProgram/BonusProgramPage')),
    exact: true,
    rights: [Roles.ROLE_ADMIN],

  },
  {
    path: appUrls.screening,
    // component: <Clients/>,
    component: lazy(() => import('routes/Screening/Screening')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_SALE_MANAGER, Roles.ROLE_ACCOUNTING, Roles.TECHNICAL_SPECIALIST],
  },
  {
    path: appUrls.screeningId,
    // component: <Clients/>,
    component: lazy(() => import('routes/Screening/ScreeningById')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_SALE_MANAGER, Roles.ROLE_ACCOUNTING],
  },
  {
    path: appUrls.requestFB,
    // component: <RequestFB/>,
    component: lazy(() => import('routes/RequestFB')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD, Roles.TECHNICAL_SPECIALIST],
  },
  {
    path: appUrls.requestTikTok,
    // component: <RequestFB/>,
    component: lazy(() => import('routes/RequestTikTok')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD, Roles.TECHNICAL_SPECIALIST],
  },
  {
    path: appUrls.system,
    // component: <System/>,  //src/routes/System.js
    component: lazy(() => import('routes/System')),
    exact: true,
    rights: [Roles.ROLE_ADMIN]
  },
  {
    path: appUrls.commissionGeneral,
    // component: <Commissions/>, //src/routes/Commissions.js
    component: lazy(() => import('routes/Commission/GeneralCommission')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD]
  },
  {
    path: appUrls.commissionIndividual,
    // component: <Commissions/>, //src/routes/Commissions.js
    component: lazy(() => import('routes/Commission/IndividualCommission')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD]
  },

  {
    path: appUrls.adAccount,
    // component: <AddAccounts/>, //src/routes/AddAccounts.js
    component: lazy(() => import('routes/AddAccounts/AddAccounts')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD]
  },
  {
    path: appUrls.accountsActive,
    // component: <AddAccounts/>, //src/routes/AddAccounts.js
    component: lazy(() => import('routes/ClientActiveStatusPage')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD]
  },
  {
    path: appUrls.createClient,
    // component: <AddAccounts/>, //src/routes/AddAccounts.js
    component: lazy(() => import('routes/CreateClient')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_SUPPORT]
  },
  // {
  //   path: "/client/clientId=:id",
  //   // component: <AddAccounts/>, //src/routes/AddAccounts.js
  //   component: lazy(() => import('routes/ClientCard/ClientCard')),
  //   // component: ()=><div>test</div>,
  //   exact: true,
  //   rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING]
  // },
  {
    path: appUrls.clientExpenses,
    component: lazy(() => import('routes/ExpensesPage')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT]
  },
  {
    path: appUrls.clientPayLine,
    component: lazy(() => import('routes/PaylinePage')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT]
  },
  {
    path: appUrls.clientAccounts,
    component: lazy(() => import('routes/ClientAccountsPage/ClientAccountsPage')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT]
  },
  {
    path: appUrls.clientSettings,
    component: lazy(() => import('routes/ClientSettings')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT]
  },
  {
    path: appUrls.clientInfo,
    component: lazy(() => import('routes/ClientInfo')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT]
  },
  {
    path: appUrls.clientFinMovement,
    component: lazy(() => import('routes/MoneyMovementPage')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT]
  },
  {
    path: appUrls.clientContracts,
    component: lazy(() => import('routes/ContractPage')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT]
  },
  ///client/clientId=:id/expenses
  {
    path: appUrls.clientAccounts,
    component: lazy(() => import('routes/ClientAccount')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT]
  },

  {
    path: appUrls.clientFinDocs,
    component: lazy(() => import('routes/FinDoc')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT]
  },
  {
    path: appUrls.clientProfitability,
    component: lazy(() => import('routes/ProfitabilityPage')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT]
  },

  {
    path: appUrls.clientClientsPage,
    component: lazy(() => import('routes/ClientsPage')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT]
  },

  {
    path: appUrls.clientFinSettings,
    component: lazy(() => import('routes/FinSettingsPage')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD, Roles.ROLE_SUPPORT]
  },

  {
    path: appUrls.reports,
    // component: <Reports/>, //src/routes/Reports.js
    component: lazy(() => import('routes/Reports/Reports')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD]
  },
  {
    path: appUrls.reportsRate,
    component: lazy(() => import('routes/Reports/Reports-rate')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD]
  },

  {
    path: appUrls.googleMcc,
    component: lazy(() => import('routes/MccGoogleAds/MCCGoogleAds')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD]
  },
  {
    path: appUrls.manualPayment,
    component: lazy(() => import('routes/ManualReplenish/ManualReplenish')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD]
  },
  {
    path: appUrls.manualDeduct,
    component: lazy(() => import('routes/ManualDeduct/ManualDeduct')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD]
  },
  {
    path: appUrls.penalty,
    component: lazy(() => import('routes/ManualPenalty')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_SUPPORT, Roles.ROLE_TEAM_LEAD]
  },
  {
    path: appUrls.teams,
    component: lazy(() => import('routes/Teams/Teams')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD]
  },
  {
    path: appUrls.googleTax,
    component: lazy(() => import('routes/GoogleTax/GoogleTax')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_TEAM_LEAD]
  },
  {
    path: appUrls.invoicePayments,
    component: lazy(() => import('routes/InvoicePayments/InvoicePayments')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_ACCOUNTING, Roles.ROLE_SALE_MANAGER]
  },

  {
    path: appUrls.advPartner,
    component: lazy(() => import('routes/ADVPartner/ADVPartner')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD]
  },

  {
    path: "*",
    component: lazy(() => import('routes/EmptyPage')),
    exact: true,
    rights: [Roles.ROLE_ADMIN, Roles.ROLE_HEAD, Roles.ROLE_FINANCIER, Roles.ROLE_ACCOUNTING, Roles.ROLE_TEAM_LEAD]
  },
];

export default function App() {
  const dispatch = useDispatch();
  const {isAuth, right, authInProcess} = useSelector(getAuth);
  const {isOpen} = useSelector(getToggleSidebar);
  const {load} = useSelector(getTableData)["isLoad"];
  const [disconnectSocket] = UseCloseSocketSession();
  const token = getCookie(authConstants.accessToken);
  const {t} = useTranslation();
  // const {data} = UseGetMessageApi(token, {skip: !token && !isAuth});
  const [socketConnection] = UseLazyGetMessageApi(token, {skip: !token || !isAuth});

  useEffect(() => {
    dispatch(CheckIsAuth())
    //   .then(res => {
    //   console.log("res", res);
    //
    //
    // })
    // if (getCookie(authConstants.accessToken) && getCookie(authConstants.accessToken) !== "undefined") {
    //   const AccessToken = getCookie(authConstants.accessToken);
    //   if (AccessToken) {
    //     let data = jwtDecode(AccessToken);
    //     let newState = {
    //       isAuth: true,
    //       userId: data.userId,
    //       right: data.AUTHORITIES_KEY[0].authority,
    //       login: data.sub || "",
    //       name: data?.userName || data.sub || "",
    //       password: data.sub || "",
    //     }
    //     dispatch(setLoadAuth(newState));
    //   }
    //   // let tokens = getCookie(authConstants.accessToken) && getCookie(authConstants.accessToken).split(".");
    //   //
    //   // if (tokens[1]) {
    //   //   // let data = JSON.parse(atob(tokens[1]));
    //   //   let data = jwtDecode(getCookie(authConstants.accessToken));
    //   //   console.log("tokens!", jwtDecode(getCookie(authConstants.accessToken)));
    //   //
    //   //   // console.log("data", data);
    //   //   let newState = {
    //   //     isAuth: true,
    //   //     userId: data.userId,
    //   //     right: data.AUTHORITIES_KEY[0].authority,
    //   //     login: data.sub || "",
    //   //     name: data?.userName || data.sub || "",
    //   //     password: data.sub || "",
    //   //   }
    //   //   dispatch(setLoadAuth(newState));
    //   // }
    // } else {
    //   let newData = {
    //     isAuth: false,
    //     right: "",
    //     login: "",
    //     password: "",
    //   }
    //   dispatch(setLoadAuth(newData));
    // }
  }, []);

  useEffect(() => {
    token && socketConnection()
  }, [token]);


  useEffect(() => {
    const onBeforeUnload = (event) => {
      console.log("disconnect 123")
      const sessionId = sessionStorage.getItem(websocketMessageTypes.SESSION_ID);
      event.preventDefault();
      disconnectSocket(sessionId);
      event.returnValue = true;
    };

    // window.addEventListener("beforeunload", onBeforeUnload);
    window.addEventListener("unload", onBeforeUnload);

    return () => {
      // window.removeEventListener("beforeunload", onBeforeUnload);
      window.removeEventListener("unload", onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // window.addEventListener("focus", () => showNewFavicon("default"));
    const showNewFaviconLocal = () => {
      showNewFavicon("default", t("HTMLTitle"))
    }
    document.addEventListener("visibilitychange", showNewFaviconLocal);
    return () => document.removeEventListener("visibilitychange", showNewFaviconLocal)

    // window.addEventListener("focus",  showNewFaviconLocal);
    // return () => {
    //   window.removeEventListener("focus",  showNewFaviconLocal)
    // }
  }, [])


  return (
    <>
      <ReloadPageAfterDeployment/>
      {/*<Router basename="/a">*/}
      <Router basename={`${baseUrl}`}>
        <Suspense fallback={
          <div className={`loadingElement w-100`}>
            <img src={image} className={"loadingImage"} alt={"Loading..."}/>
          </div>}>
          {isAuth && <Header/>}
          {isAuth && <Sidebar/>}

          <div
            style={{
              paddingTop: "80px",
              background: "#F0F0F0",
              minHeight: "100vh",
            }}
            className={`px-4 pb-3 ${
              isAuth && (isOpen ? "open_sidebar" : "close_sidebar")
            }`}
          >
            <Switch>
              <PublicRoute
                path={"/login"}
                isAuth={isAuth}
                load={load}
                right={right}
                exact={true}
              >
                <Login/>
              </PublicRoute>
              <ProtectedRoutes
                authInProcess={authInProcess}
                isAuth={isAuth}
                right={right}
                ROUTES={ROUTES}/>
            </Switch>
          </div>
        </Suspense>
      </Router>
    </>

  );
}
