import i18next from "i18next";

export const FormatterTypeReplenishment = (type) => {
  switch (type) {
    case "paymentByInvoice":
      return i18next.t("invoicePayment");
    case "other":
      return i18next.t("otherPayment");
    case "blockedRefund":
      return i18next.t("blockedAccountRefund");
    case "bonus":
      return i18next.t("bonusDeposit");
    default:
      return "";
  }
}