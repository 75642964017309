export const cabinetType = Object.freeze({
  CENTER_CLIENT: "CENTER_CLIENT",
  CLIENT: "CLIENT",
  CC: "CC",
  C_OWN_PL: "C_OWN_PL",
  ADMIN: "ADMIN",
  SYSTEM: "SYSTEM",
  OLD: "OLD"
});

export const cabinetTypeShort = Object.freeze({
  CC: "CC",
  C: "C"
});