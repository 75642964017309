import React, {memo, useState, useMemo, useCallback} from 'react';
import {useSelector} from "react-redux";
import {Load} from "components/load/Load";
import "./ClientCardTop.scss";
import {PaylineTypeFormatter} from "utils/PaylineTypeFormatter";
import {ServiceTypesImg} from "common/seriveTypes";
import {Link, useHistory, useLocation} from "react-router-dom";
import {
  DDMMYYYYFullFormat,
  FormatterDateFromTo,
  YYYYMMDDFullFormat
} from "utils/date";
import {useTranslation} from "react-i18next";
import GoToCabinet from "components/GoToCabinet/GoToCabinet";
import {shortenString} from "utils/Formatters/StringFormat/shortenString";
import {ClientCabinetTypes} from "../../constants/ClientCabinetTypes";
import Tooltips from "../../components/Tooltips";
import {appUrls} from "../../constants/appUrls";

const ClientCardTimeBlock = memo(({time, timezone}) => {
  const {t} = useTranslation();
  return (
    <div className={"py-2 px-3 rounded-12 client-info-block"}>
      <div className={"text-grey5 font-size-12"}>{t("timezone")}</div>
      <div className={"text-grey6 font-weight-medium pb-2"}>{time} {new Date().getFullYear()}</div>
      <div className={"text-grey6"}>{timezone}</div>
    </div>
  )
});

const ClientCardBalanceBlock = memo(({balance, payerType}) => {
  const {t} = useTranslation();
  return (
    <div className={"py-2 px-3 rounded-12 mx-3 client-info-block"}>
      <div className={"text-grey5 font-size-12"}>{t("balance")}</div>
      {/*<div className={"text-grey6 font-size-18 font-weight-medium pb-1"}>{balance} $</div>*/}
      <div className={"text-grey6 font-size-18 font-weight-medium pb-1"}>{
        balance?.map(item => {
          return <div key={item.currency}>{item.sum} {" "}{item.currency}</div>
        })
      }</div>
      <div className={"text-grey6"}>{PaylineTypeFormatter(payerType, t)}</div>
    </div>
  )
});

const ClientCardPaylineInfoBlock = memo(({paylineName, id, isHideBtn, hasPL}) => {
  const {t} = useTranslation();
  const history = useHistory();
  return (
    <div className={"py-2 px-3 rounded-12 mr-3 client-info-block"}>
      <div className={"text-grey5 font-size-12"}>{t("Payline")}</div>
      <div className={"text-grey6 font-weight-medium pb-2"} title={paylineName}>{shortenString(paylineName, 60)}</div>
      {!isHideBtn &&
        <Tooltips tooltipId={"payLine"}
                  className={"d-inline"}
                  text={t(hasPL ? "plDetailTooltip" : "plNotSetTooltip")}>
          <button className={"text-primary bg-transparent border-0"}
                  disabled={!hasPL}
                  onClick={() => history.push(appUrls.clientPayLine.formatURL({id}))}>
            {t("details")} >
          </button>
        </Tooltips>
      }
    </div>
  )
});

const ServiceItem = memo(({row}) => {
  const {t} = useTranslation();
  // const commissionInfo = commission?.filter(item => +item.serviceId === +serviceType)[0];
  const commissionType = row?.type === "GENERAL" ? t("general") : t("individualShort");
  // const commissionPercent = commissionInfo?.commissionGrid?.[0]?.amount;

  return (
    <div className={"pr-2 pt-2"}>
      <div className="d-flex pb-1 align-items-center">
        {ServiceTypesImg(row.serviceType, t, "auto", 16, false)}
        <div className={"text-grey6 pl-2 text-nowrap"}>{row.commission} %</div>
      </div>
      <div className={"text-grey5"}>{commissionType}</div>
    </div>
  )
})

const ClientCardBonusCommissionBlock = memo(({commissions}) => {
  const [activeTab, setActiveTab] = useState("commission");
  const {t} = useTranslation();

  // const onBonusesActivate = useCallback(()=>{
  //   activeTab !== "bonuses" && setActiveTab("bonuses")
  // }, [activeTab]);

  const onCommissionActivate = useCallback(() => {
    activeTab !== "commission" && setActiveTab("commission")
  }, [activeTab]);

  return (
    <div className={"pb-2 rounded-12 client-info-block"}>
      <div className={"d-inline-flex align-items-center tab-wrap"}>
        <div className={`tab-item ${activeTab === "commission" ? "active" : ""}`}
             onClick={onCommissionActivate}>
          {t("commissions")}
        </div>
        {/*<div className={`tab-item ${activeTab === "bonuses" ? "active" : ""}`}*/}
        {/*     onClick={onBonusesActivate}>*/}
        {/*  {t("bonuses")}*/}
        {/*</div>*/}
      </div>
      <div className={"d-flex pl-3 flex-wrap"}>
        {commissions.map(item => {
          return (
            <React.Fragment key={item?.serviceType}><ServiceItem row={item}/></React.Fragment>
          )
        })}
      </div>
    </div>
  )
});

const TabsBlock = memo(({id}) => {
  const {pathname} = useLocation();
  const lastItem = pathname.split('/').filter(x => x.length > 0).pop();
  const {t} = useTranslation();

  const tabsInfo = useMemo(() => ([
    {
      title: t("accounts"),
      path: `/client/clientId=${id}/accounts`
    },

    {
      title: t("finDoc"),
      path: `/client/clientId=${id}/finDoc`
    },
    // {
    //   title: "Договоры",
    //   path: `/client/clientId=${id}/contracts`
    // },
    {
      title: t("expenses"),
      path: `/client/clientId=${id}/expenses`
    },
    {
      title: t("finMovement"),
      path: `/client/clientId=${id}/finMovement`
    },
    {
      title: t("info"),
      path: `/client/clientId=${id}/info`
    },
    // {
    //   title: "Доходность",
    //   path: `/client/clientId=${id}/profitability`
    // },
    // {
    //   title: "Клиенты",
    //   path: `/client/clientId=${id}/clients`
    // },
    // {
    //   title: "Настройка финмодуля",
    //   path: `/client/clientId=${id}/finSettings`
    // },
    {
      title: t("settings"),
      path: `/client/clientId=${id}/settings`
    }
  ]), []);

  return (
    <div className={"account-tabs-wrapper"}>
      {tabsInfo.map((item) => {
        return <Link
          to={item.path}
          className={`account-tabs-item ${item?.path?.includes(lastItem) ? "active" : ""}`}
          key={item.path}>{item.title}</Link>
      })}
    </div>
  )
})

const ClientCardTop = memo(({id}) => {
  const {clientCardHead} = useSelector(state => state.tableData);
  const {isLoad} = useSelector((state) => state.tableData);
  const {t} = useTranslation();

  if (isLoad || !clientCardHead) {
    return <Load/>
  }

  return (
    <>
      <div className={"d-flex justify-content-between align-items-center"}>
        <div>
          <h1
            className={"font-size-18 mb-0 font-weight-bold"} title={clientCardHead?.name}>
            {shortenString(clientCardHead?.name, 60)} ({t(clientCardHead?.cabinetType)})</h1>
          <p className={"text-grey6 mb-0"}>{clientCardHead?.email}</p>
          <p className={"text-grey6 mb-0"}>{clientCardHead?.alias}</p>
        </div>

        <div className={"d-flex align-items-stretch"}>
          <div className={"p-4"}>
            <GoToCabinet clientId={id}/>
          </div>
          <ClientCardTimeBlock timezone={`${clientCardHead?.timeZone?.name}, ${clientCardHead?.timeZone?.hour}`}
            // time={clientCardHead?.time}
                               time={FormatterDateFromTo(clientCardHead?.time, YYYYMMDDFullFormat, DDMMYYYYFullFormat)}
          />
          <ClientCardBalanceBlock payerType={clientCardHead?.payerType}
                                  balance={clientCardHead?.balance}/>
          <ClientCardPaylineInfoBlock
            hasPL={clientCardHead?.payLineId}
            isHideBtn={clientCardHead?.cabinetType === ClientCabinetTypes.C_CC_PL}
            paylineName={clientCardHead?.paylineName || clientCardHead?.name}
            id={id}/>
          <ClientCardBonusCommissionBlock commissions={clientCardHead?.commissions}/>
        </div>
      </div>
      <TabsBlock id={id}/>
    </>
  );
});

export default ClientCardTop;