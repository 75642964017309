import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ClientCard from "./ClientCard/ClientCard";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getTableData, loadTableData} from "../redux/reducers/tableData";
import PeriodCalendar from "../components/PeriodCalendar";
import {DefaultColumnFilter, filterTypes, getExcel, GlobalFilter, TRBody, TRHead} from "../utils/FilteredTable";
import {SelectColumnFilter} from "../components/Table/SelectedColumnFilter";
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from "react-table";
import Pagination from "../common/Paginations";
import BTable from "react-bootstrap/Table";
import {DDMMYYYYFormat, FormatterDateFromTo, last30Days, YYYYMMDDFormat} from "../utils/date";
import {useTranslation} from "react-i18next";
import {getAuth} from "../redux/reducers/auth";
import {Roles} from "../common/RoleFormatter";
import {Load} from "../components/load/Load";
import i18next from "i18next";
import {cabinetType} from "../constants/CabinetType";

function Table({
                 columns,
                 data,
                 pageSizeDefault,
                 exportName,
                 isExport,
                 period,
                 onHandelChangePeriod
               }) {

  const dataClient = useSelector(getTableData)["clientCardHead"] || [];
  const {t} = useTranslation();
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex, pageSize, globalFilter},
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        pageSize: pageSizeDefault,
      },
      autoResetPage: false,
    },

    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  );

  return (
    <>
      <div className={"d-flex full-wrap-filtered-table bg-white p-3"}>
        <div className={"d-flex"}>
          <div className={"mr-4"} style={{width: "370px"}}>
            <PeriodCalendar defaultPeriod={period}
                            onChangePeriod={onHandelChangePeriod}
                            period={period}
                            additionBtn={true}
                            forAllPeriod={[
                              FormatterDateFromTo(dataClient.createdAt, YYYYMMDDFormat, DDMMYYYYFormat),
                              FormatterDateFromTo(undefined, YYYYMMDDFormat, DDMMYYYYFormat),
                            ]}
            />
          </div>

          <GlobalFilter
            gotoPage={gotoPage}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>

        {rows.length > pageSize && <Pagination
          pageIndex={pageIndex}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          gotoPage={gotoPage}
          pageCount={pageCount}
          canNextPage={canNextPage}
          nextPage={nextPage}
        />}
      </div>

      <div className={"filtered-table-wrapper"}>
        <BTable striped bordered hover size="sm" className={"bg-white filtered-table"} {...getTableProps()}>
          <thead>
          {headerGroups.map((headerGroup) => <TRHead {...headerGroup.getHeaderGroupProps()}
                                                     headerGroup={headerGroup}/>)}
          </thead>

          <tbody {...getTableBodyProps()}>
          <TRBody page={page} lastItemBold={false} length={headerGroups[0].headers.length} prepareRow={prepareRow}/>
          </tbody>

        </BTable>
      </div>

      <div className={"column-table-filter d-flex justify-content-between bg-white"}>
        <div className={"p-3 text-right"}>
          {isExport && <button className={"btn btn-outline-primary ml-auto"}
                               onClick={() => getExcel(exportName, headerGroups, rows, prepareRow)}>
            {t("exportToExcel")}
          </button>}
        </div>
      </div>
    </>
  )
}

export const CreatorFormatter = (type, t) => {
  if (t === undefined) {
    t = i18next.t
  }

  switch (type) {
    case cabinetType.CENTER_CLIENT:
      return t("centerClient");
    case cabinetType.CLIENT:
      return t("client");
    case cabinetType.ADMIN:
      return t("ADMIN");
    case cabinetType.SYSTEM:
      return t("SYSTEM");
    case cabinetType.OLD:
      return "";
    default:
      return "";

  }
}

const CreatorCell = ({row}) => {
  const {t} = useTranslation();
  return <div>
    <div className={"mb-1"}>{CreatorFormatter(row?.creatorType, t)}</div>
    <div>{row?.email || null}</div>
  </div>
}

const MoneyMovementPage = () => {
  const {pathname} = useLocation();
  const clientId = pathname.match(/clientId=(\d+)/)[1];
  const [period, setPeriod] = useState(last30Days);
  const dataForTable = useSelector(getTableData)["moneyMovement"] || [];
  const isLoad = useSelector(getTableData)["isLoad"] || false;
  const {right} = useSelector(getAuth);
  const isDisabled = right === Roles.ROLE_SUPPORT;

  const dispatch = useDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    console.log("clientId", clientId)
    dispatch(loadTableData("moneyMovement", {
      id: clientId,
      dateFrom: FormatterDateFromTo(period[0], DDMMYYYYFormat, YYYYMMDDFormat),
      dateTo: FormatterDateFromTo(period[1], DDMMYYYYFormat, YYYYMMDDFormat),
    })).then((res) => {
      res && dispatch(loadTableData("clientCardHead", {id: clientId})).then(res => {
        res && dispatch(loadTableData("sidebarInfo"))
      });
    });
  }, [clientId]);

  const columns = useMemo(() => [
    {
      Header: t("operationType"),
      accessor: 'operationTypeText',
      Filter: SelectColumnFilter,
      filter: 'equals',
      disableSortBy: true,
      canResize: false,
      combineTh: true,
      widthCol: "300px",
    },

    {
      Header: t("fromWhom"),
      accessor: 'fromText',
      Filter: false,
      disableSortBy: true,
      canResize: false,
      combineTh: true,
      center: true,
      Cell: ({row}) => {
        console.log("row.original", row.original)
        return <div>
          {row.original.fromText1}{row.original.fromText2 ? <br/> : ""}
          {row.original?.fromText4 ? row.original?.fromText4 : ""}
          {row.original.fromText4 ? <br/> : ""}
          {row.original.fromText2}{row.original.fromText3 ? <br/> : ""}
          {row.original.fromText3} {row.original.fromText5 ? <br/> : ""}
          {row.original.fromText5} {row.original.aliasFrom ? <br/> : ""}
          {row.original.aliasFrom}

        </div>
      },
    },
    {
      Header: t("toWhom"),
      accessor: 'toText',
      Filter: false,
      disableSortBy: true,
      canResize: false,
      combineTh: true,
      center: true,
      Cell: ({row}) => {
        return <div>
          {row.original.toText1}{row.original.toText1 ? <br/> : ""}
          {row.original.toText2}{row.original.toText2 ? <br/> : ""}
          {row.original.toText3}{row.original.toText3 ? <br/> : ""}
          {row.original.toText5}{row.original.toText5 ? <br/> : ""}
          {row.original.aliasTo}
        </div>
      },
    },

    {
      Header: t("paymentDateTime"),
      accessor: 'dateText',
      Filter: false,
      disableSortBy: true,
      canResize: false,
      combineTh: true,
      center: true,
    },
    {
      Header: t("paymentSum"),
      accessor: 'paymentSum',
      Filter: false,
      disableSortBy: true,
      canResize: false,
      combineTh: true,
      center: true,
      Cell: ({row}) => row.original.paymentSumText
    },
    {
      Header: t("paymentCurrency"),
      accessor: 'paymentCurrency',
      Filter: false,
      disableSortBy: true,
      canResize: false,
      combineTh: true,
      center: true,
    },
    {
      Header: t("accountSumPayment"),
      accessor: 'accountSumPayment',
      Filter: false,
      disableSortBy: true,
      canResize: false,
      combineTh: true,
      center: true,
      Cell: ({row}) => row.original.accountSumPaymentText
    },
    {
      Header: t("accountCurrency"),
      accessor: 'accountCurrency',
      Filter: false,
      disableSortBy: true,
      canResize: false,
      combineTh: true,
      center: true,
    },
    {
      Header: t("user"),
      accessor: 'creatorFullData',
      Filter: false,
      disableSortBy: true,
      canResize: false,
      combineTh: true,
      center: true,
      Cell: ({row}) => <CreatorCell row={row.original?.creator}/>
    },
    //creatorFullData
  ], [t, clientId]);

  const onHandelChangePeriod = useCallback((e) => {
    setPeriod([
      FormatterDateFromTo(e[0], YYYYMMDDFormat, DDMMYYYYFormat),
      FormatterDateFromTo(e[1], YYYYMMDDFormat, DDMMYYYYFormat),
    ]);
    dispatch(loadTableData("moneyMovement", {
      id: clientId,
      dateFrom: FormatterDateFromTo(e[0], YYYYMMDDFormat, YYYYMMDDFormat),
      dateTo: FormatterDateFromTo(e[1], YYYYMMDDFormat, YYYYMMDDFormat),
    }))
  }, []);

  if (isLoad) {
    return <Load/>
  }

  return (
    <div>
      <ClientCard id={clientId}/>
      <Table
        columns={columns}
        data={dataForTable || []}
        pageSizeDefault={10}
        exportName={`Money Movement_${FormatterDateFromTo(period[0], DDMMYYYYFormat, DDMMYYYYFormat)}_${FormatterDateFromTo(period[1], DDMMYYYYFormat, DDMMYYYYFormat)}`}
        isExport={!isDisabled}
        period={period}
        onHandelChangePeriod={onHandelChangePeriod}
      />
    </div>
  );
};

export default MoneyMovementPage;


