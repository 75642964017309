export const OpenLineType = (t)=>([
  {value: 0, label: t("notSetOpenLine")},
  {value: 1, label: "KZ"},
  {value: 2, label: "NR KZ"},
  // {value: 3, label: "XXXX"},
  // {value: 4, label: "Asia"},

]);

export const OpenLineTextFormatter = (type, t)=>{
  switch (type){
    case 0:
      return t("notSetOpenLine");
    case 1:
      return  "KZ";
    case 2:
      return "NR KZ";
    case 3:
      return "XXX";
    case 4:
      return "Asia";
    default:
      return "unknown OL";
  }
}