import React, {memo, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAuth} from "reduxStore/reducers/auth";
import api from "../utils/api";
import {loadTableData} from "reduxStore/reducers/tableData";

import {FilteredTable} from "../utils/FilteredTable";
import {SelectColumnFilter} from "../components/Table/SelectedColumnFilter";
import swal from "sweetalert";
import Tooltips from "../components/Tooltips";
import OpenLineModal from "../components/Modal/OpenLineModal";
import {OpenLineTextFormatter, OpenLineType} from "../utils/OpenLineType";
import ClientTagModal from "../components/Modal/ClientTagModal";
import TagIcon from "assets/images/tag-icon.svg";
import Support from "assets/images/support.svg"
import {Link} from "react-router-dom";
import {MultiSelectedColumnFilter} from "../components/Table/MultiSelectedColumnFilter";
import {getTableData} from "../redux/reducers/tableData";
import {Roles} from "../common/RoleFormatter";
import {useTranslation} from "react-i18next";
import {Load} from "../components/load/Load";
import GoToCabinet from "../components/GoToCabinet/GoToCabinet";
import AttentiveIcon from "assets/images/attentionIcon.svg?svgr";
import generateExcel from "zipcelx";
import {shortenString} from "../utils/Formatters/StringFormat/shortenString";
import {appCPUrl} from "../constants/appUrls";

const generateClientsExcel = (rows, HeadersForExcel, DataRows) => {
  const config = {
    filename: `Clients_list`,
    sheet: {
      data: []
    }
  };

  const dataSet = config.sheet.data;


  dataSet.push(HeadersForExcel);


  if (rows.length > 0) {
    dataSet.push(...DataRows(rows));
    // rows.forEach((row) => {
    //   // prepareRow(row)
    //
    //   const dataRow = DataRows(row);
    //
    //   dataSet.push(dataRow);
    // });
  } else {
    dataSet.push([
      {
        value: "No data",
        type: "string"
      }
    ]);
  }

  return generateExcel(config);


}


const DataRows = (rows) => {

  return rows.map((row) => {    // prepareRow(row)
    return [
      {
        value: row.payLineName,
        type: "string"
      },
      {
        value: row.isSelfRegText,
        type: "string"
      },
      {
        value: row.phone,
        type: "string",
      },
      {
        value: row.clientType,
        type: "string",
      },

      {
        value: row.centerClientLogin,
        type: "string",
      },
      {
        value: row.replenishmentType,
        type: "string",
      },
      {
        value: row.alias,
        type: "string",
      },
      {
        value: row.openLineText,
        type: "string",
      },

      {
        value: row.clientTag,
        type: "string",
      },
      {
        value: row.managementTag,
        type: "string",
      },


      {
        value: row?.creationData, //creationData
        type: "string",
      },
      {
        value: row.question1,
        type: "string",
      },
      {
        value: row.question2,
        type: "string",
      },
      {
        value: row.question3,
        type: "string",
      },
      {
        value: row.question4,
        type: "string",
      },
    ];
  });
}


const ShowActionColumn = memo(({row}) => {
  const {right} = useSelector(getAuth);
  const disabledBtn = right === Roles.ROLE_SUPPORT || right === Roles.ROLE_FINANCIER;
  const {t} = useTranslation();

  const [showOpenLineModal, setShowOpenLineModal] = useState({
    show: false,
    id: null,
  });

  const [showClientTagModal, setShowClientTagModal] = useState({
    show: false,
    id: null
  })

  return (
    <>
      {showOpenLineModal.show && <OpenLineModal
        id={showOpenLineModal.id}
        show={showOpenLineModal.show}
        closeCallback={() => setShowOpenLineModal({
          show: false,
          id: null,
        })}

      />}

      {showClientTagModal.show && <ClientTagModal
        id={showClientTagModal.id}
        clientTag={showClientTagModal.clientTag}
        show={showClientTagModal.show}
        managementTag={showClientTagModal.managementTag}
        closeCallback={() => setShowClientTagModal({
          show: false,
          id: null,
        })}

      />}
      <div className={"d-flex align-items-center"}>
        <GoToCabinet clientId={row.id}/>
        <Tooltips
          text={t("setOpenLine")} delayShow={100} tooltipId={`OL_${row.id}`} className=""
        >
          <button
            className={"btn btn-outline-primary ml-2"}
            hidden={disabledBtn}
            onClick={() => setShowOpenLineModal({
              show: true,
              id: row.id
            })}
          ><img src={Support} alt={"open line settings"}/>
          </button>
        </Tooltips>
        <Tooltips
          text={t("setClientsTag")} delayShow={100} tooltipId={`tag_${row.id}`} className=""
        >
          <button
            className={"btn btn-outline-primary px-2 ml-2"}
            hidden={disabledBtn}
            onClick={() => setShowClientTagModal({
              show: true,
              id: row.id,
              managementTag: row.managementTag,
              clientTag: row.clientTag,
            })}
          >
            <img src={TagIcon} alt={"client's tag setting"}/>
          </button>
        </Tooltips>
      </div>
    </>
  )
});


const PLCellFormatter = memo(({row, userRight}) => {
  const inner = (userRight !== Roles.ROLE_FINANCIER) ? <Link className={"text-primary"}
                                                             title={row.values.payLineName}
                                                             to={`/client/clientId=${row.original.id}/expenses`}>
    {shortenString(row.values.payLineName, 60)}
  </Link> : <div title={row.values.payLineName}>{shortenString(row.values.payLineName, 60)}</div>;

  return <div className={"d-flex align-items-center"}>
    {inner}
  </div>
});

const IsSelfReg = memo(({row}) => {
  return <div className={"d-flex align-items-center"}>
    {row.original?.isSelfReg ? <Tooltips
      tooltipId={`${row?.original?.id}_isSelfReg`}
      text={"The client came through self-registration"}
      className={""}
    ><AttentiveIcon className={"d-block mr-2"} width={24} height={24} fill={"#0571E6"}/></Tooltips> : null}
  </div>
})


export const optionsSelfReg = [
  {value: "Yes", label: "self-reg"},
  {value: "No", label: "prod-reg"}

  // {value: i18next.t("yes"), label: "self-reg"},
  // {value: i18next.t("no"), label: "prod-reg"}
]

export default function Clients() {
  const {right: userRight} = useSelector(getAuth);
  const dispatch = useDispatch();
  const {managementTag, managementTagIsLoad} = useSelector(getTableData) || [];
  const {clients, clientsIsLoad} = useSelector(getTableData) || [];
  const {clientTag, clientTagIsLoad} = useSelector(getTableData) || [];
  const {t} = useTranslation();
  const isNotAllowCreateUser = userRight === Roles.ROLE_SUPPORT;

  const adminKeyURl = appCPUrl;

  console.log("adminKeyURl", adminKeyURl)

  const isShowAllClients = false;


  useEffect(() => {
    let _isMount = true;
    _isMount && dispatch(loadTableData("managementTag"))
      .then((res) => {
        res && dispatch(loadTableData("clientTag"))
          .then((res) => {
            res && dispatch(loadTableData("clients", {disabledOn: 0}))
              .then((res) => {
                // console.log("res", res?.payload?.clients)
                // setFilteredClients(res?.payload?.clients)
                res && dispatch(loadTableData("sidebarInfo"))
              })
          })
      })
    return () => (_isMount = false);

  }, []);

  function changeManagementType({replenishmentType, name, id}) {
    if (replenishmentType !== "AUTO") {
      return
    }
    swal({
      text: t("turnOnManualManagement", {name}),
      icon: "warning",
      buttons: [t("no"), t("yes")],
      closeOnClickOutside: false,
      className: "confirm-simple-modal"
    }).then((response) => {
      if (response) {
        // todo change url whet it will be ready
        api(`/clients/switch-to-manual-fin-management/${id}`, {method: "PUT"})
          .then(() =>
            dispatch(loadTableData("clients", {disabledOn: +!isShowAllClients}))
          );
      }
    })
  }


  const columns = React.useMemo(
    () => [
      {
        Header: t("paylineName"),
        accessor: 'payLineName',
        Filter: MultiSelectedColumnFilter,
        filter: 'includesSome',
        disableSortBy: true,
        Cell: ({row}) => <PLCellFormatter row={row} right={userRight}/>

      },

      {
        Header: t("selfRegistration"),
        accessor: 'isFirstCreationText',
        // Filter: ({...rest}) => SelectColumnFilter({...rest}, optionsSelfReg),
        Filter: SelectColumnFilter,
        // Filter: MultiSelectedColumnFilter,
        filter: 'equals',
        disableSortBy: true,
        Cell: ({row}) => <IsSelfReg row={row}/>

      },

      {
        Header: t("clientPhone"),
        accessor: 'phone',
        Filter: SelectColumnFilter,
        filter: 'equals',
        disableSortBy: true,
      },
      {
        Header: t("clientType"),
        accessor: 'clientType',
        Filter: SelectColumnFilter,
        filter: 'equals',
        disableSortBy: true,
        widthCol: "100px",
      },
      {
        Header: t("belongToCC"),
        accessor: 'centerClientLogin',
        Filter: SelectColumnFilter,
        filter: 'equals',
        disableSortBy: true,
      },
      {
        Header: t("finManagement"),
        accessor: 'replenishmentType',
        Filter: SelectColumnFilter,
        disableSortBy: true,
        filter: 'equals',
        Cell: ({row}) => {
          return <>
            {row.original.replenishmentType === "AUTO" ? <div>
              <Tooltips
                text={!row.original.plNotActiveNotManual ?
                  t("switchActiveStatus1") :
                  t("switchActiveStatus2")}
                tooltipId={row.original.email}
                className={"test"}>
                <button
                  disabled={!row.original.plNotActiveNotManual || userRight !== Roles.ROLE_ADMIN || userRight !== Roles.ROLE_HEAD}
                  className={`btn btn-success`}
                  onClick={() => changeManagementType(row.original)}
                >
                  {t("autoManagement")}
                </button>
              </Tooltips>
            </div> : t("manualManagement")}
          </>
        }
      },
      {
        // Header: t("clientName"),
        Header: t("alias"),
        accessor: 'alias',
        Filter: SelectColumnFilter,
        filter: 'equals',
        disableSortBy: true,
      },
      {
        Header: t("openLine"),
        accessor: 'openLine',
        // Filter: SelectColumnFilter,
        Filter: ({...rest}) => SelectColumnFilter({...rest}, OpenLineType(t)),
        filter: 'equals',
        Cell: ({row}) => {
          return <>{OpenLineTextFormatter(+row.original.openLine, t)}</>;
        },
        disableSortBy: true,
      },
      {
        Header: t("clientTag"),
        accessor: 'clientTag',
        Filter: SelectColumnFilter,
        filter: 'equals',
        disableSortBy: true,
      },
      {
        Header: t("managementTag"),
        accessor: 'managementTag',
        // Filter: false,
        Filter: SelectColumnFilter,
        filter: 'equals',
        disableSortBy: true,
      },
      {
        Header: t("actions"),
        Filter: false,
        disableSortBy: true,
        accessor: 'buttons',
        Cell: ({row}) => {
          return <ShowActionColumn row={row.original}/>
        }
      },
    ],
    [userRight, t, managementTag, clientTag]
  );

  const HeadersForExcel = React.useMemo(() => [
    {
      value: t("paylineName"),
      type: "string",
    },
    {
      value: t("selfRegistration"),
      type: "string",
    },
    {
      value: t("clientPhone"),
      type: "string",
    },
    {
      value: t("clientType"),
      type: "string",
    },
    {
      value: t("belongToCC"),
      type: "string",
    },
    {
      value: t("finManagement"),
      type: "string",
    },
    {
      value: t("clientName"),
      type: "string",
    },
    {
      value: t("openLine"),
      type: "string",
    },
    {
      value: t("clientTag"),
      type: "string",
    },
    {
      value: t("managementTag"),
      type: "string",
    },
    {
      value: t("creatingData"),
      type: "string",
    },
    {
      value: t("question1"),
      type: "string",
    },
    {
      value: t("question2"),
      type: "string",
    },
    {
      value: t("question3"),
      type: "string",
    },
    {
      value: t("question4"),
      type: "string",
    },
  ], [t]);


  const registrationHandleClick = useCallback((e) => {
    e.preventDefault();
    !isNotAllowCreateUser && api(`/clients/prepare-registration`, {
      method: "POST",
      data: null,
      credentials: "include",
    })
      .then((res) => {
        res === "OK" && window.open(`${adminKeyURl}/signup`, '_blank');
      })

  }, []);

  if (clientTagIsLoad || managementTagIsLoad || clientsIsLoad) {
    return <Load/>
  }

  return (
    <>
      <div className={"d-flex align-items-center pb-4"}>
        <h1 className={"mb-0 pr-4"}>{t("clients")}</h1>
        <button className={"btn btn-primary"}
                hidden={isNotAllowCreateUser}
                onClick={registrationHandleClick}>{t("signupClient")}</button>
        <button className={"btn btn-outline-primary px-4 ml-3"}
                onClick={() => generateClientsExcel(clients, HeadersForExcel, DataRows)}>{t("exportToExcel")}</button>

      </div>

      <FilteredTable
        columns={columns}
        dataFromComponent={clients}
        showToggleColumn={false}
        isGlobalFilter={true}
        dataName={"clients"}
        dataBody={{disabledOn: 0}}/>
    </>
  );
}
