import {SimpleSpanProcessor, WebTracerProvider} from '@opentelemetry/sdk-trace-web';
import {DocumentLoadInstrumentation} from '@opentelemetry/instrumentation-document-load';
import {FetchInstrumentation} from '@opentelemetry/instrumentation-fetch';
import {XMLHttpRequestInstrumentation} from '@opentelemetry/instrumentation-xml-http-request';
import {registerInstrumentations} from '@opentelemetry/instrumentation';
import {OTLPTraceExporter} from '@opentelemetry/exporter-trace-otlp-http';
import {MeterProvider, PeriodicExportingMetricReader} from '@opentelemetry/sdk-metrics';
import {OTLPMetricExporter} from '@opentelemetry/exporter-metrics-otlp-http';
import {metrics, trace} from '@opentelemetry/api';
import {Resource} from '@opentelemetry/resources';
import {
  ATTR_SERVICE_NAME,
} from '@opentelemetry/semantic-conventions';

// if +__IS_OPEN_TELEMETRY_ENABLED__ equal number 1 openTelemetry will be added,
// if +__IS_OPEN_TELEMETRY_ENABLED__ equal number 0 openTelemetry will not be added
if (+__IS_OPEN_TELEMETRY_ENABLED__) {

  const resource = Resource.default().merge(
    new Resource({
      [ATTR_SERVICE_NAME]: __OPEN_TELEMETRY_SERVICE_NAME__,
    }),
  );

// creating tracers
// set exporter for send data to OTLP-compatible backend (example, Jaeger)
  const traceExporter = new OTLPTraceExporter({
    url: __OPEN_TELEMETRY_TRACE_URL__, // URL your OTLP-compatible backend
  });
  // Create provider for trace with using TracerConfig
  const tracerProvider = new WebTracerProvider({
    resource: resource,
    spanProcessors: new SimpleSpanProcessor(traceExporter)
    //   [
    //   new BatchSpanProcessor(exporter, {
    //     maxQueueSize: 100, // Max amount of spans in the queue
    //     maxExportBatchSize: 50, // Max amount of spans in one batch
    //     scheduledDelayMillis: 5000, // Delay before sending batch (in milliseconds)
    //     exportTimeoutMillis: 30000, // timeout for exports
    //   }),
    // ],
  });

  tracerProvider.register();
  trace.setGlobalTracerProvider(tracerProvider);


// CREATE METRICS EXPORTER
  const metricExporter = new OTLPMetricExporter({
    url: __OPEN_TELEMETRY_METRICS_URL__, // URL your OTLP-compatible backend
  });

  // Like the SpanProcessor, the metric reader sends metrics to the exporter
  const metricReader = new PeriodicExportingMetricReader({
    exporter: metricExporter,
    // Default is 60000ms (60 seconds). Set to 10 seconds for demonstration purposes.
    exportIntervalMillis: 10000,
  });

  const meterProvider = new MeterProvider({
    resource: resource,
    readers: [metricReader],
  });

  metrics.setGlobalMeterProvider(meterProvider);
//  register instrumentations for automatic data collection
  registerInstrumentations({
    instrumentations: [
      new DocumentLoadInstrumentation(),
      new FetchInstrumentation({
        propagateTraceHeaderCorsUrls: [__REQUEST_URL__],
      }),
      new XMLHttpRequestInstrumentation(),
    ],
  });

} else {
  console.log('Tracing is disabled.');
}


